<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      d="M17.5 23a.5.5 0 0 1-.5.5H1a.5.5 0 0 1-.5-.5V1A.5.5 0 0 1 1 .5h10.182a.5.5 0 0 1 .337.13l5.818 5.311a.5.5 0 0 1 .163.37V23zm-1-.5V6.53L10.988 1.5H1.5v21h15z"
    />
    <path
      d="M11.5 5.5V1h-1v5a.5.5 0 0 0 .5.5h6v-1h-5.5zM14.5 20.5h-11v-13h11v13zm-1-1v-11h-9v11h9z"
    />
    <path
      d="M4 11.5h10a.5.5 0 1 0 0-1H4a.5.5 0 1 0 0 1zM4 14.5h10a.5.5 0 1 0 0-1H4a.5.5 0 1 0 0 1zM4 17.5h10a.5.5 0 1 0 0-1H4a.5.5 0 1 0 0 1z"
    />
    <path d="M7.5 8v12a.5.5 0 1 0 1 0V8a.5.5 0 0 0-1 0z" />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
